import React from 'react';
import keyBy from 'lodash/keyBy'
import swal from 'sweetalert'
import { Col, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from "react-bs-datatable";
import Modal from 'react-bootstrap/Modal'
import Apis from '../../../../../../api';
import CommentList from '../../../MaterialEquipmentLogs/compontes/commentList';
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const getBusinessDatesCount = (startDate, endDate) => {
    let count = 0;
    let curDate = startDate;
    while (curDate < endDate) {
        const dayOfWeek = new Date(curDate).getDay();

        const isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
        if (isWeekend) {
            count++;
        }
        curDate = curDate + 24 * 60 * 60 * 1000
    }

    return count;
}

const headers = [
    { title: '#', prop: 'id', isSortable: true },
    { title: 'Work Structure', prop: 'work_structure', isFilterable: true, isSortable: true },
    { title: 'Task', prop: 'task', isFilterable: true, isSortable: true },
    { title: 'Team', prop: 'team', isFilterable: true, isSortable: true },
    { title: 'Requirements', prop: 'requirements', isFilterable: true, isSortable: true },
    { title: 'Required on site (Date)', prop: 'required_on_site', isFilterable: true, isSortable: true },
    { title: 'Lead time', prop: 'lead_time', isFilterable: true, isSortable: true },
    { title: 'Required to order by (Date)', prop: 'required_to_order_by', isFilterable: true, isSortable: true },
    { title: 'Ordered date', prop: 'ordered_date', isFilterable: true, isSortable: true },
    { title: 'Type', prop: 'type', isFilterable: true, isSortable: true },
    { title: 'Order Status', prop: 'status' },
    { title: 'Action', prop: 'action' }
];


const LogList = ({ state, material, equipment, task, team, suppliers, taskmaterial, taskequipment, refresh, setRefresh }) => {
    const materialById = keyBy(material, '_id')
    const equipmentByID = keyBy(equipment, '_id')
    const taskByID = keyBy(task, '_id')
    const teamByID = keyBy(team, '_id')
    const supplierById = keyBy(suppliers, '_id')
    const [log_list, setLogList] = React.useState([])
    const [show, setShow] = React.useState(false);
    const [comment_show, setCommentShow] = React.useState(false);
    const [order_confirm_show, setOrderConfirmShow] = React.useState(false)
    const [tableData, setTableData] = React.useState({
        id: '',
        sub_project: '',
        phase_plan: '',
        work_packages: '',
        task_name: '',
        requirement_type: 'Material',
        quantity: '',
        deilaver_quantity: '',
        name: '',
        supplier_company: '',
        supplier_email: '',
        supplier_contact_details: '',
        required_on_site_date: '',
        required_to_order_by_date: '',
        ordered_date: '',
        notes: '',
        admin_note: '',
        comments: ''
    })
    const [comment_refresh, setCommentRefresh] = React.useState(false)
    const [error, setError] = React.useState({
        error: '',
        color: '',
        open: false
    })
    const [members, setMembers] = React.useState([])

    React.useEffect(() => {
        Apis.getAllMember().then((res) => {
            setMembers(res.data.data.member)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    React.useEffect(() => {
        let list = []
        let demo = null
        var i = 0;
        const user = JSON.parse(localStorage.getItem('user-info'))

        taskmaterial && taskmaterial.map((elm) => {
            var email = supplierById[materialById[elm.material]?.supplier]?.email
            if (email === user.email) {
                var startDate = new Date(new Date(taskByID[elm.task_id]?.startDate).getTime() - materialById[elm.material]?.lead_time * 24 * 60 * 60 * 1000).getTime()
                var endDate = taskByID[elm.task_id]?.startDate
                let day = getBusinessDatesCount(startDate, endDate)
                startDate = new Date(new Date(startDate).getTime() - day * 24 * 60 * 60 * 1000).getTime()
                var order_status = 'confirmed'

                if (elm.order_status === 'confirmed') {
                    order_status = 'completed'
                }

                if (state.type_code === 1 || state.type_code === 2) {
                    i++
                    demo = {
                        id: i,
                        work_structure: <> <Link onClick={() => handleShow(elm, 'Material')} >{taskByID[elm.task_id]?.subproject.label + '>' + taskByID[elm.task_id]?.phase_plane.label + '>' + taskByID[elm.task_id]?.work_packages.label}</Link></>,
                        task: taskByID[elm.task_id]?.name,
                        company: supplierById[materialById[elm.material]?.supplier]?.company_name,
                        company_email: supplierById[materialById[elm.material]?.supplier]?.email,
                        team: teamByID[taskByID[elm.task_id]?.team.value]?.name,
                        requirements: materialById[elm.material]?.material_name + ', ' + elm.quantity + ', ' + supplierById[materialById[elm.material]?.supplier]?.company_name,
                        required_on_site: new Date(taskByID[elm.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[elm.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[elm.task_id]?.startDate).getFullYear(),
                        lead_time: materialById[elm.material]?.lead_time,
                        required_to_order_by: new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear(),
                        ordered_date: elm.order_status !== 'pending' ? new Date(elm.ordered_time).getDate() + '/' + parseInt(new Date(elm.ordered_time).getMonth() + 1) + '/' + new Date(elm.ordered_time).getFullYear() : 'Not yet Ordered',
                        type: 'Material',
                        timeStamp: elm.timeStamp,
                        status: elm.order_status !== 'pending' ? <div className='text-capitalize'>{elm.order_status}</div> : 'Upcoming',
                        action: elm.order_status !== 'pending' && elm.order_status !== 'completed' ? <>
                            <div className="d-flex addremove_btn">
                                <a className='btn btn-success btn-sm text-capitalize' onClick={() => OrderMaterial(elm, order_status)} >{order_status}</a>
                                <a className="btn btn-primary btn-sm" onClick={() => commentMaterial(elm, 'Material')} ><i class="fa fa-comments" aria-hidden="true"></i></a>
                            </div> </> : ''
                    }

                    if (state.team === '' && state.order_status === '' && state.date === '') {
                        list.push(demo)
                    } else if (state.team === taskByID[elm.task_id]?.team.value && state.order_status === '' && state.date === '') {
                        list.push(demo)
                    } else if (state.team === taskByID[elm.task_id]?.team.value && state.order_status === elm.order_status && state.date === '') {
                        list.push(demo)
                    } else if (state.team === '' && state.order_status === elm.order_status && state.date === '') {
                        list.push(demo)
                    } else if (state.team === '' && state.order_status === '' && new Date(state.date).getTime() === taskByID[elm.task_id]?.startDate) {
                        list.push(demo)
                    } else if (state.team === '' && state.order_status === elm.order_status && new Date(state.date).getTime() === taskByID[elm.task_id]?.startDate) {
                        list.push(demo)
                    } else if (state.team === taskByID[elm.task_id]?.team.value && state.order_status === elm.order_status && new Date(state.date).getTime() === taskByID[elm.task_id]?.startDate) {
                        list.push(demo)
                    } else if (state.team === taskByID[elm.task_id]?.team.value && state.order_status === '' && new Date(state.date).getTime() === taskByID[elm.task_id]?.startDate) {
                        list.push(demo)
                    }
                }
            }
            return list
        })
        taskequipment && taskequipment.map((elm) => {
            var email = supplierById[equipmentByID[elm.equipment]?.supplier]?.email
            if (email === user.email && elm.order_status) {
                var startDate = new Date(new Date(taskByID[elm.task_id]?.startDate).getTime()).getTime()
                var endDate = taskByID[elm.task_id]?.startDate
                let day = getBusinessDatesCount(startDate, endDate)
                startDate = new Date(new Date(startDate).getTime() - day * 24 * 60 * 60 * 1000).getTime()
                var order_status = 'confirmed'

                if (elm.order_status == 'confirmed') {
                    order_status = 'completed'
                }
                if (state.type_code === 1 || state.type_code === 3) {
                    i++
                    demo = {
                        id: i,
                        work_structure: <> <Link onClick={() => handleShow(elm, 'Equipment')}>{taskByID[elm.task_id]?.subproject.label + '>' + taskByID[elm.task_id]?.phase_plane.label + '>' + taskByID[elm.task_id]?.work_packages.label}</Link></>,
                        task: taskByID[elm.task_id]?.name,
                        company: supplierById[equipmentByID[elm.equipment]?.supplier]?.company_name,
                        company_email: supplierById[equipmentByID[elm.equipment]?.supplier]?.email,
                        team: teamByID[taskByID[elm.task_id]?.team.value]?.name,
                        requirements: equipmentByID[elm.equipment]?.equipment + ',' + elm.quantity + ' ,' + supplierById[equipmentByID[elm.equipment]?.supplier]?.company_name,
                        required_on_site: new Date(taskByID[elm.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[elm.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[elm.task_id]?.startDate).getFullYear(),
                        lead_time: '',
                        required_to_order_by: new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear(),
                        ordered_date: elm.order_status !== 'pending' ? new Date(elm.ordered_time).getDate() + '/' + parseInt(new Date(elm.ordered_time).getMonth() + 1) + '/' + new Date(elm.ordered_time).getFullYear() : 'Not yet Ordered',
                        type: 'Equipment',
                        timeStamp: elm.timeStamp,
                        status: elm.order_status !== 'pending' ? <div className='text-capitalize'>{elm.order_status}</div> : 'Upcoming',
                        action: elm.order_status !== 'pending' && elm.order_status !== 'completed' ? <>
                            <div className="d-flex addremove_btn">
                                <a className='btn btn-success btn-sm text-capitalize' onClick={() => OrderEquipment(elm, order_status)} >{order_status}</a>
                                <a className="btn btn-primary btn-sm" onClick={() => commentMaterial(elm, 'Equipment')} ><i class="fa fa-comments" aria-hidden="true"></i></a>
                            </div> </> : ''
                    }
                    if (state.team === '' && state.order_status === '' && state.date === '') {
                        list.push(demo)
                    } else if (state.team === taskByID[elm.task_id]?.team.value && state.order_status === '' && state.date === '') {
                        list.push(demo)
                    } else if (state.team === taskByID[elm.task_id]?.team.value && state.order_status === elm.order_status && state.date === '') {
                        list.push(demo)
                    } else if (state.team === '' && state.order_status === elm.order_status && state.date === '') {
                        list.push(demo)
                    } else if (state.team === '' && state.order_status === '' && new Date(state.date).getTime() === taskByID[elm.task_id]?.startDate) {
                        list.push(demo)
                    } else if (state.team === '' && state.order_status === elm.order_status && new Date(state.date).getTime() === taskByID[elm.task_id]?.startDate) {
                        list.push(demo)
                    } else if (state.team === taskByID[elm.task_id]?.team.value && state.order_status === elm.order_status && new Date(state.date).getTime() === taskByID[elm.task_id]?.startDate) {
                        list.push(demo)
                    } else if (state.team === taskByID[elm.task_id]?.team.value && state.order_status === '' && new Date(state.date).getTime() === taskByID[elm.task_id]?.startDate) {
                        list.push(demo)
                    }
                }
            }
            return list
        })
        var lists = []
        list.sort(compareNumbers)
        list.reverse()
        list.map((elm, $key) => {
            demo = elm
            demo.id = $key + 1
            lists.push(demo)
            return lists
        })

        setLogList(lists)
    }, [taskmaterial, taskequipment, state, material, equipment, task, team, suppliers])

    const compareNumbers = (a, b) => {

        return a.timeStamp - b.timeStamp;
    }

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
    })


    const handleShow = (record, type) => {
        setShow(true)
        var supplier = ''
        var name = ''
        var task = taskByID[record.task_id]
        var endDate = taskByID[record.task_id]?.startDate

        if (type === 'Material') {
            supplier = supplierById[materialById[record.material]?.supplier]
            name = materialById[record.material]?.material_name
            var startDate = new Date(new Date(taskByID[record.task_id]?.startDate).getTime() - materialById[record.material]?.lead_time * 24 * 60 * 60 * 1000).getTime()
            let day = getBusinessDatesCount(startDate, endDate)
            startDate = new Date(new Date(startDate).getTime() - day * 24 * 60 * 60 * 1000).getTime()
        } else {
            supplier = supplierById[equipmentByID[record.equipment]?.supplier]
            name = equipmentByID[record.equipment]?.equipment
            var startDate = new Date(new Date(taskByID[record.task_id]?.startDate).getTime()).getTime()
            let day = getBusinessDatesCount(startDate, endDate)
            startDate = new Date(new Date(startDate).getTime() - day * 24 * 60 * 60 * 1000).getTime()
        }
        setTableData({
            id: record._id,
            sub_project: task?.subproject.label,
            phase_plan: task?.phase_plane.label,
            work_packages: task?.work_packages.label,
            task_name: task?.name,
            requirement_type: type,
            name: name,
            supplier_company: supplier?.company_name,
            supplier_email: supplier?.email,
            supplier_contact_details: supplier?.company_name,
            required_on_site_date: new Date(taskByID[record.task_id]?.startDate).getDate() + '/' + parseInt(new Date(taskByID[record.task_id]?.startDate).getMonth() + 1) + '/' + new Date(taskByID[record.task_id]?.startDate).getFullYear(),
            required_to_order_by_date: new Date(startDate).getDate() + '/' + parseInt(new Date(startDate).getMonth() + 1) + '/' + new Date(startDate).getFullYear(),
            ordered_date: record.order_status ? new Date(record.ordered_time).getDate() + '/' + parseInt(new Date(record.ordered_time).getMonth() + 1) + '/' + new Date(record.ordered_time).getFullYear() : 'Not yet Ordered',
            notes: record.task_notes,
            admin_note: record.admin_notes,
            comments: '',
            quantity: '',
            delivery_quantity: '',
        })

    }

    const commentMaterial = (record, type) => {
        var supplier = ''
        if (type === 'Material') {
            supplier = supplierById[materialById[record.material]?.supplier]
        } else {
            supplier = supplierById[equipmentByID[record.equipment]?.supplier]
        }


        setTableData({
            id: record._id,
            requirement_type: type,
            comments: '',
            supplier_company: supplier?.company_name,
        })
        setCommentShow(true)
    }

    const handleOrderComment = (record) => {

        if (record.comments == '') {
            setError({
                ...error,
                error: 'Comment is required',
                color: 'error',
                open: true
            })
        } else {
            setTableData({
                ...tableData,
                ['comments']: '',
            })
            const user = JSON.parse(localStorage.getItem('user-info'))
            const commentData = {
                order_id: record.id,
                created_by_id: user.id,
                type: record.requirement_type.toLowerCase(),
                date: new Date().getTime(),
                comment: record.comments
            }
            Apis.addOrderComments(commentData).then((res) => {
                swal({
                    title: res.data.message,
                    icon: "success"
                }).then((result) => {
                    setCommentRefresh(!comment_refresh)
                })
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    const OrderMaterial = (record, status) => {

        if (status == 'confirmed') {
            swal({
                title: "Are you sure want to " + status + " this material?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((result) => {
                if (result) {
                    const user = JSON.parse(localStorage.getItem('user-info'))
                    const raw = {
                        material_id: record._id,
                        status: status,
                        user_id: user.id,
                        ordered_time: new Date().getTime()
                    }
                    Apis.placeTaskMaterialOreded(raw).then((res) => {
                        swal({
                            title: res.data.message,
                            icon: "success"
                        }).then((result) => {
                            setRefresh(!refresh)
                        })
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            });
        } else {
            setOrderConfirmShow(!order_confirm_show)
            var name = materialById[record.material]?.material_name
            setTableData({
                id: record._id,
                requirement_type: 'Material',
                name: name,
                quantity: record.quantity,
                delivery_quantity: '',
                supplier_delivery_note: ''
            })
        }


    }

    const OrderEquipment = (record, status) => {
        if (status == 'confirmed') {
            swal({
                title: "Are you sure want to " + status + " this equipment?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((result) => {
                if (result) {
                    const user = JSON.parse(localStorage.getItem('user-info'))
                    const raw = {
                        equipment_id: record._id,
                        user_id: user.id,
                        status: status,
                        ordered_time: new Date().getTime()
                    }
                    Apis.placeTaskEquipmentOreded(raw).then((res) => {
                        swal({
                            title: res.data.message,
                            icon: "success"
                        }).then((result) => {
                            setRefresh(!refresh)
                        })
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            });
        } else {
            setOrderConfirmShow(!order_confirm_show)
            var name = equipmentByID[record.equipment]?.equipment
            setTableData({
                id: record._id,
                requirement_type: 'Equipment',
                name: name,
                quantity: record.quantity,
                delivery_quantity: '',
                supplier_delivery_note: ''
            })
        }


    }

    const OrderCompeleted = (record) => {

        if (record.delivery_quantity == '') {
            setError({
                ...error,
                error: 'Delivery Quantity is required',
                color: 'error',
                open: true
            })
        } else if (record.delivery_quantity <= 0) {
            setError({
                ...error,
                error: 'Please enter vaild quantity',
                color: 'error',
                open: true
            })
        } else if (record.supplier_delivery_note == '') {
            setError({
                ...error,
                error: 'Note is required',
                color: 'error',
                open: true
            })
        } else {

            if (record.requirement_type === 'Equipment') {
                swal({
                    title: "Are you sure want to complete this equipment?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((result) => {
                    if (result) {
                        setOrderConfirmShow(false)
                        const user = JSON.parse(localStorage.getItem('user-info'))
                        const raw = {
                            equipment_id: record.id,
                            user_id: user.id,
                            status: 'completed',
                            delivery_quantity: record.delivery_quantity,
                            supplier_delivery_note: record.supplier_delivery_note,
                            ordered_time: new Date().getTime()
                        }
                        Apis.placeTaskEquipmentOreded(raw).then((res) => {
                            swal({
                                title: res.data.message,
                                icon: "success"
                            }).then((result) => {
                                setRefresh(!refresh)
                            })
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                });
            } else {
                swal({
                    title: "Are you sure want to complete this material?",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                }).then((result) => {
                    if (result) {
                        setOrderConfirmShow(false)
                        const user = JSON.parse(localStorage.getItem('user-info'))
                        const raw = {
                            material_id: record.id,
                            user_id: user.id,
                            status: 'completed',
                            delivery_quantity: record.delivery_quantity,
                            supplier_delivery_note: record.supplier_delivery_note,
                            ordered_time: new Date().getTime()
                        }
                        Apis.placeTaskMaterialOreded(raw).then((res) => {
                            swal({
                                title: res.data.message,
                                icon: "success"
                            }).then((result) => {
                                setRefresh(!refresh)
                            })
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                });
            }

        }


    }

    return (
        <>
            <Snackbar
                open={error.open}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                autoHideDuration={5000}
                onClose={() => setError({
                    ...error,
                    open: false
                })}
            >
                <Alert onClose={() => setError({
                    ...error,
                    open: false
                })} severity={error.color} sx={{ width: '100%' }}>
                    {error.error}
                </Alert>
            </Snackbar >
            <div className="table-responsive">
                <DatatableWrapper
                    body={log_list}
                    headers={headers}
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 15,
                            options: [15, 25, 50, 100, 200]
                        }
                    }}>
                    <Row className="mb-4 p-2">
                        <Col
                            xs={12}
                            lg={8}
                            className="d-flex flex-col justify-content-start align-items-end"
                        >
                            <Row>
                                <Col xs={12}
                                    lg={8}
                                    className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                        paginationRange={2}
                                    /></Col>
                                <Col xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                            </Row>
                        </Col>

                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                            <Filter placeholder="Type here to search" />
                        </Col>
                    </Row>
                    <Table>
                        <TableHeader />
                        <TableBody />
                    </Table>
                    <Row className="mb-4 p-2">
                        <Col
                            xs={12}
                            lg={8}
                            className="d-flex flex-col justify-content-start align-items-end"
                        >
                            <Row>
                                <Col xs={12}
                                    lg={8}
                                    className="d-flex flex-col justify-content-start align-items-end"><Pagination alwaysShowPagination
                                        paginationRange={2}
                                    /></Col>
                                <Col xs={12}
                                    sm={6}
                                    lg={4}
                                    className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"><PaginationOptions /></Col>
                            </Row>
                        </Col>

                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >

                        </Col>
                    </Row>
                </DatatableWrapper>
            </div>
            <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setShow(!show)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{tableData.requirement_type + ' Log'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <div className="table-responsive">
                                <table className='table table-striped table-bordered'>
                                    <tbody className='table-group-divider'>
                                        <tr>
                                            <th>Sub Project</th>
                                            <td>{tableData.sub_project}</td>
                                            <th>Phase Plan</th>
                                            <td>{tableData.phase_plan}</td>
                                        </tr>
                                        <tr>
                                            <th>Work Package</th>
                                            <td>{tableData.work_packages}</td>
                                            <th>Task Name</th>
                                            <td>{tableData.task_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Requirement Type</th>
                                            <td>{tableData.requirement_type}</td>
                                            <th>Material/Equipment Name</th>
                                            <td>{tableData.name}</td>
                                        </tr>
                                        <tr>
                                            <th>Supplier Company Type</th>
                                            <td>{tableData.supplier_company}</td>
                                            <th>Supplier Email</th>
                                            <td>{tableData.supplier_email}</td>
                                        </tr>
                                        <tr>
                                            <th>Supplier Contact Datails</th>
                                            <td>{tableData.supplier_contact_details}</td>
                                            <th>Required On Site Date</th>
                                            <td>{tableData.required_on_site_date}</td>
                                        </tr>
                                        <tr>
                                            <th>Required To Order By Date</th>
                                            <td>{tableData.required_to_order_by_date}</td>
                                            <th>Ordered Date</th>
                                            <td>{tableData.ordered_date}</td>
                                        </tr>
                                        <tr>
                                            <th>Notes</th>
                                            <td colSpan='3'>{tableData.notes}</td>
                                        </tr>
                                        <tr>
                                            <th>Admin Notes</th>
                                            <td colSpan='3'>{tableData.admin_note}</td>

                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    </div>


                </Modal.Body>
            </Modal>
            <Modal show={comment_show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setCommentShow(!comment_show)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{tableData.requirement_type + ' Notes'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-10">
                            <textarea className="form-control" value={tableData.comments} placeholder="Leave a comment here" onChange={(e) => setTableData({
                                ...tableData,
                                ['comments']: e.target.value
                            })}></textarea>
                        </div>
                        <div className="col-2 m-auto">
                            <button className='btn btn-prbtn btn-primary waves-effect waves-light' onClick={() => handleOrderComment(tableData)} ><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
                        </div>
                        <div className="col-12 mt-3 ">
                            <CommentList
                                order_id={tableData.id}
                                comment_refresh={comment_refresh}
                                type={tableData.requirement_type.toLowerCase()}
                                members={members}
                                company={tableData.supplier_company}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={order_confirm_show} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={() => setOrderConfirmShow(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{tableData.requirement_type + ' Order Completed'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 mt-3">
                            <div className="table-responsive">
                                <table className='table table-striped table-bordered'>
                                    <tbody className='table-group-divider'>
                                        <tr>
                                            <th>Material/Equipment Name</th>
                                            <td>{tableData.name}</td>
                                            <th>Requirement Quantity</th>
                                            <td>{tableData.quantity}</td>
                                        </tr>
                                        <tr>
                                            <th>Delivering Quantity</th>
                                            <td colSpan='3'> <input
                                                type="number"
                                                id="quantity"
                                                name='quantity'
                                                className="form-control"
                                                onChange={(e) => setTableData({
                                                    ...tableData,
                                                    ['delivery_quantity']: e.target.value
                                                })}
                                                placeholder='Enter Delivering Quantity'
                                            /></td>
                                        </tr>
                                        <tr>
                                            <th>Note</th>
                                            <td colSpan='3'><textarea className="form-control" value={tableData.comments} placeholder="Leave a comment here" onChange={(e) => setTableData({
                                                ...tableData,
                                                ['supplier_delivery_note']: e.target.value
                                            })}></textarea></td>
                                        </tr>
                                    </tbody>

                                </table>

                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary" onClick={() => OrderCompeleted(tableData)}>Complete</button>
                    <button type="button" className="btn btn-secondary" onClick={() => setOrderConfirmShow(false)}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LogList;