import React from 'react'
import { arrayOf, string, shape } from 'prop-types'
import { PieChart, Legend, Pie, ResponsiveContainer, Cell, Sector } from 'recharts'
import groupBy from 'lodash/groupBy'
import keyBy from 'lodash/keyBy'
import Apis from '../../../../api'

const REASON_CODES_COLOR_PALETTE = {
    RC01: '#DCCFBD',
    RC02: '#C8CBBD',
    RC03: '#B3C6BC',
    RC04: '#9CBBBC',
    RC05: '#85AFBB',
    RC06: '#7793A9',
    RC07: '#697697',
    RC08: '#5E5B7E',
    RC09: '#584D71',
    RC10: '#523F64',
    RC11: '#7085A0',
    RC12: '#7EA1B2',
    RC13: '#6D7E9C',
}



const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;
    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} style={{ fontSize: '16px', fontWeight: '600' }}>
                {payload.name}

            </text>
            <text x={cx} y={cy + 20} dy={10} textAnchor="middle" fill={fill} style={{ fontSize: '16px', fontWeight: '500' }}>
                {`${(percent * 100).toFixed(0)}%`}

            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius + 2}
                outerRadius={outerRadius + 3}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius - 65}
                outerRadius={outerRadius - 62}
                fill={fill}
            />

        </g>
    );
};

const Reasongraph = ({ teamId, fromDate, toDate, subproject, phase_plane, work_packages, location_id }) => {
    const [completedTasks, setCompletedTasks] = React.useState([])
    const [commitTasks, setCommitTask] = React.useState([])
    const [reasonCodes, setReasonCodes] = React.useState([])
    const [state, setState] = React.useState({
        activeIndex: 0
    })

    React.useEffect(() => {
        Apis.reasonCodes().then((res) => {
            setReasonCodes(res.data.data.reason_codes)
        }).catch((error) => {
            console.log(error.response)
        })
    }, [])

    React.useEffect(() => {
        const filter = [{ is_active: false }]
        let filters = [{ type: 'Task' }]
        let demo
        if (teamId && teamId !== '$$all') {
            demo = { team_id: teamId }
            filter.push(demo)

        }
        if (fromDate) {
            demo = { closing_date: { $gte: new Date(fromDate).getTime() } }
            filter.push(demo)
        }
        if (toDate) {
            demo = { closing_date: { $lte: new Date(toDate).getTime() } }
            filter.push(demo)
        }

        if (subproject && subproject.value !== '$$all') {
            demo = { "subproject.value": subproject.value }
            filters.push(demo)
        }
        if (phase_plane && phase_plane.value !== '$$all') {
            demo = { "phase_plane.value": phase_plane.value }
            filters.push(demo)
        }
        if (work_packages && work_packages.value !== '$$all') {
            demo = { "work_packages.value": work_packages.value }
            filters.push(demo)
        }
        if (location_id) {
            demo = { location_id: location_id }
            filters.push(demo)
        }

        const raw = {
            "filter": filter,
            "filters": filters
        }
        Apis.getAnalytice(raw).then((res) => {
            setCommitTask(res.data)

        }).catch((error) => {
            console.log(error.response.data)
        })

    }, [fromDate, teamId, toDate, subproject, phase_plane, work_packages, location_id])

    React.useEffect(() => {
        const plans = commitTasks.commit ? commitTasks.commit : []
        const tasks = commitTasks.tasks ? commitTasks.tasks : []
        let list = []
        plans.map((plan) => {
            const task = tasks.find(elm => elm._id == plan.task_id)
            if (task) {
                list.push(plan)
                return list
            }
            return list
        })
        setCompletedTasks(list)
    }, [commitTasks])



    const filteredCompletedTasks = completedTasks.filter((t) => !!t.reason_code_id)
    const completedTasksByReasonId = groupBy(filteredCompletedTasks, (task) => task.reason_code_id)
    const reasonCodesById = keyBy(reasonCodes, '_id')
    const reasonCodesByName = keyBy(reasonCodes, 'reason')
    const data = Object.entries(completedTasksByReasonId).map(([key, value]) => (
        {
            name: reasonCodesById[key]?.reason,
            value: value.length,
            color: reasonCodesById[key]?.color,
        }))
    const onPieEnter = (_, index) => {
        setState({
            activeIndex: index,
        });
    };

    /*  const LegendWrapperStyle = { lineHeight: 4 } */
    /* const lable = { value: '', offset: 5, position: 'bottom' } */
    return (<>
        <div style={{ height: "600px" }}>
            <ResponsiveContainer width="100%" height="100%" >
                <PieChart >
                    {/* <Tooltip
                    formatter={(value, name) => [
                        `${((value / filteredCompletedTasks.length) * 100).toFixed(0)}%`,
                        name,
                    ]}
                /> */}
                    <Legend
                        formatter={(value) => reasonCodesByName[value]?.reason}
                        verticalAlign="top"
                    />
                    <Pie
                        activeIndex={state.activeIndex}
                        activeShape={renderActiveShape}
                        data={data}
                        filteredCompletedTasks={filteredCompletedTasks}
                        /*  cx="45%"
                         cy="50%" */
                        labelLine={false}
                        innerRadius={110}
                        fill="#8884d8"
                        dataKey="value"
                        isAnimationActive={false}
                        outerRadius={170}
                        onMouseEnter={onPieEnter}


                    >
                        {data.map((entry) => (
                            <Cell key={entry.name} fill={entry.color} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>


        </div>

    </>
    )
}

Reasongraph.propTypes = {
    completedTasks: arrayOf(shape({})),
    reasonCodes: arrayOf(
        shape({
            _id: string,
            ref_id: string,
            reason: string,
            description: string,
        })
    ),
}

export default Reasongraph
