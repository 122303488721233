import React from 'react'
import Auth from './Auth'
import Alert from '../../../helpers/Alert'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import Footer from '../Layout/Footer'
import Apis from '../../../api'
import config from '../../../config'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Papa from 'papaparse'

function Setting() {
    const { values, error, handleChange, handleUpadteSystemInfo, getSystemInfo, setError } = Auth()
    const [loginImage, setLoginImage] = React.useState({
        id: '',
        button_name: 'Add',
        BackgroundImage: './assets/images/login-background.jpg',
        show_text: true,
        show_text_id: '',

    })
    const [response, setResponse] = React.useState({
        total_users: 0,
        uploaded_users: 0,
        duplicate_users: 0,
        incorrect_data: 0,
        status: false
    })

    const [refresh, setRefresh] = React.useState(false);
    const [show_loader, setLoaderShow] = React.useState(false);
    React.useEffect(() => { getSystemInfo('project_name', values) }, [values.id])
    React.useEffect(() => {
        Apis.getSetting().then((res) => {
            var data = res.data.setting
            if (data.length > 0) {
                const loginImageSetting = data.find(setting => setting.key === 'login_image');
                const loginImageTextShow = data.find(setting => setting.key === 'show_login_image_text');
                if (loginImageSetting) {
                    setLoginImage({
                        ...loginImageSetting,
                        id: loginImageSetting._id,
                        button_name: 'Update',
                        BackgroundImage: config.api_url + '/api/login-image/view/' + loginImageSetting._id + '?refresh=' + refresh,
                        show_text_id: loginImageTextShow ? loginImageTextShow._id : '',
                        show_text: loginImageTextShow ? loginImageTextShow.value : true
                    })
                }

            }
        }).catch((error) => {
            console.log("Setting error", error);
        });
    }, [refresh]);

    React.useEffect(() => {
        if (loginImage.show_text_id !== '') {
            const raw = {
                'key': 'show_login_image_text',
                'value': loginImage.show_text ? true : false
            }
            Apis.updateCalender(raw).then((res) => {

            }).catch((error) => {
                console.log(error)
            });
        } else {
            const raw = {
                'key': 'show_login_image_text',
                'value': loginImage.show_text ? true : false
            }
            Apis.addSetting(raw).then((res) => {
                setRefresh(!refresh)

            }).catch((error) => {
                console.log(error)
            });
        }
    }, [loginImage.show_text])

    /* Import CSV */

    const handleImportCSV = () => {

        setError({
            ...error,
            open: false
        })

        const formData = new FormData();
        const expectedHeaders = ["Type (Milestone / Tasks)", "Milestone / Task Description", "Completion Date", "Sub Project", "Phase Plan", "Work Package", "Task Duration", "Location", "Team", "Member Email", "Discipline", "Crew Size", "WBS Code", "Completed (Yes = 1  / No = 0)", "Committed Date (YYYY-MM-DD)", "Reason Code", "Root Cause Analysis", "Member First Name", "Member Last Name", "Notes"]
        if (values.csv_files) {
            Papa.parse(values.csv_files, {
                complete: (result) => {
                    if (result.data.length > 0) {
                        const headers = result.data[0];
                        console.log(headers)
                        if (headers.length !== expectedHeaders.length) {
                            setError({
                                ...error,
                                error: 'CSV invalid!',
                                color: 'error',
                                open: true
                            })
                            return false;
                        }
                        for (let i = 0; i < expectedHeaders.length; i++) {
                            if (headers[i] !== expectedHeaders[i]) {
                                setError({
                                    ...error,
                                    error: 'CSV header invalid! ' + headers[i],
                                    color: 'error',
                                    open: true
                                })
                                return false;
                            }
                        }

                        formData.append('files', values.csv_files);
                        setLoaderShow(true)
                        Apis.uploadImportCSV(formData).then((res) => {
                            setError({
                                ...error,
                                error: 'CSV imported ',
                                color: 'success',
                                open: true
                            })
                            setRefresh(!refresh)
                            setLoaderShow(false)

                        }).catch((error) => {
                            setError({
                                ...error,
                                error: 'Something went wrrong ',
                                color: 'error',
                                open: true
                            })
                            setLoaderShow(false)
                        });

                    }
                },
                header: false,
            });


        } else {
            setError({
                ...error,
                error: 'Please Select The CSV File',
                color: 'error',
                open: true
            })
        }
    }
    /* Add Login Image */
    const handleAddOrUpdateImage = () => {

        const formData = new FormData();
        if (loginImage.id !== '') {
            if (values.files) {
                formData.append('id', loginImage.id);
                formData.append('files', values.files);
                Apis.updateLogingImage(formData).then((res) => {
                    setError({
                        ...error,
                        error: 'Image Uloaded',
                        color: 'success',
                        open: true
                    })
                    setRefresh(!refresh)

                }).catch((error) => {
                    setError({
                        ...error,
                        error: 'Login Image is to Large. Login Image size is less then 1 Mb',
                        color: 'error',
                        open: true
                    })
                });
            } else {
                setError({
                    ...error,
                    error: 'Please Select The File',
                    color: 'error',
                    open: true
                })
            }

        } else {
            if (values.files) {
                formData.append('key', 'login_image');
                formData.append('files', values.files);
                Apis.addLogingImage(formData).then((res) => {
                    setError({
                        ...error,
                        error: 'Image Uloaded',
                        color: 'success',
                        open: true
                    })
                    setRefresh(!refresh)
                }).catch((error) => {
                    setError({
                        ...error,
                        error: 'Some Thing Went Wrrong!',
                        color: 'error',
                        open: true
                    })
                });
            } else {
                setError({
                    ...error,
                    error: 'Please Select The File',
                    color: 'error',
                    open: true
                })
            }
        }


    };

    /* Import Member CSV */

    const handleImportMemberCSV = () => {
        setError({
            ...error,
            open: false
        })

        const formData = new FormData();
        const expectedHeaders = ["First Name", "Last Name", "Abrv.", "Email"]
        if (values.csv_member_files) {
            
            Papa.parse(values.csv_member_files, {
                complete: (result) => {
                    if (result.data.length > 0) {
                        const headers = result.data[0];
                        console.log(headers)
                        if (headers.length !== expectedHeaders.length) {
                            setError({
                                ...error,
                                error: 'CSV invalid!',
                                color: 'error',
                                open: true
                            })
                            return false;
                        }
                        for (let i = 0; i < expectedHeaders.length; i++) {
                            if (headers[i] !== expectedHeaders[i]) {
                                setError({
                                    ...error,
                                    error: 'CSV header invalid! ' + headers[i],
                                    color: 'error',
                                    open: true
                                })
                                return false;
                            }
                        }

                        formData.append('files', values.csv_member_files);
            setLoaderShow(true)
            Apis.uploadMembereImportCSV(formData).then((res) => {
                var data = res.data.data
                setResponse({
                    ...response,
                    total_users: data.total_users,
                    uploaded_users: data.uploaded_users,
                    duplicate_users: data.duplicate_users,
                    incorrect_data: data.incorrect_data,
                    status: true
                })
                setLoaderShow(false)
                setError({
                    ...error,
                    error: 'CSV imported ',
                    color: 'success',
                    open: true
                })

            }).catch((error) => {
                setError({
                    ...error,
                    error: 'CSV size is less then 1 Mb',
                    color: 'error',
                    open: true
                })
                setLoaderShow(false)
            });

                    }
                },
                header: false,
            });



            
        } else {
            setError({
                ...error,
                error: 'Please Select the member CSV File',
                color: 'error',
                open: true
            })
        }
    }
    return (
        <>
            <div id="layout-wrapper">
                <Header title='Change Project Name| P2IC'></Header>
                <Alert error={error}></Alert>
                <Sidebar title='' show=''></Sidebar>
                <div className="main-content">
                    <div className="page-content dashboard_content">
                        <div className="container-fluid">
                            <div className="page-title-box">
                                <div className="row align-items-center">
                                    <div className="col-md-8">
                                        <h6 className="page-title">Change Project Name</h6>
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item active">Profile</li>
                                            <li className="breadcrumb-item active">Change Project Name</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="row pr_tagsrow">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className=" shadow-none card">
                                                        <div className='card-block'>
                                                            <div className="account-box">
                                                                <div className="card-box shadow-none p-4">
                                                                    <div className="p-4">

                                                                        <div className="form-group mb-3">
                                                                            <label for="projectName" className="form-label">Project Name</label>
                                                                            <input type="text" className="form-control" name='project_name' value={values.project_name} id="project_name" placeholder='Enter Project Name' onChange={handleChange} />
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-12 text-end">
                                                                                <button className="btn btn-primary w-md waves-effect waves-light" onClick={() => handleUpadteSystemInfo(values)} >Update</button>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className=" shadow-none card">
                                                        <div className='card-block'>
                                                            <div className="account-box">
                                                                <div className="card-box shadow-none p-4">
                                                                    <div className="p-4">

                                                                        <div className="form-group mb-3">
                                                                            <label for="projectName" className="form-label">Import LAP CSV</label>
                                                                            <a style={{ paddingLeft: '10px', marginTop: '20px' }} href="./assets/doc/sample.csv" target="_blank" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Download sample csv" className="waves-effect">
                                                                                <span><i class="fa fa-download" aria-hidden="true"></i></span>
                                                                            </a>
                                                                            <input
                                                                                type="file"
                                                                                className="form-control"
                                                                                name="files"
                                                                                id="fileInput"
                                                                                accept=".csv"
                                                                                onChange={(e) => {
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'csv_files',
                                                                                            value: e.target.files[0],
                                                                                            type: 'file',
                                                                                        },
                                                                                    });
                                                                                }} />
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-12 text-end">
                                                                                <button className="btn btn-primary w-md waves-effect waves-light" onClick={() => handleImportCSV()} >Import</button>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12">
                                                    <div className=" shadow-none card">
                                                        <div className='card-block'>
                                                            <div className="account-box">
                                                                <div className="card-box shadow-none p-4">
                                                                    <div className="p-4">
                                                                        <div className="form-group mb-3">
                                                                            <label for="projectName" className="form-label">Import Member CSV</label>
                                                                            <a style={{ paddingLeft: '10px', marginTop: '20px' }} href="./assets/doc/member-sample.csv" target="_blank" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Download sample csv" className="waves-effect">
                                                                                <span><i class="fa fa-download" aria-hidden="true"></i></span>
                                                                            </a>
                                                                            <input
                                                                                type="file"
                                                                                className="form-control"
                                                                                name="files"
                                                                                id="fileInput"
                                                                                accept=".csv"
                                                                                onChange={(e) => {
                                                                                    handleChange({
                                                                                        target: {
                                                                                            name: 'csv_member_files',
                                                                                            value: e.target.files[0],
                                                                                            type: 'file',
                                                                                        },
                                                                                    });
                                                                                }} />
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <div className="col-12 text-end">
                                                                                <button className="btn btn-primary w-md waves-effect waves-light" onClick={() => handleImportMemberCSV()} >Import</button>
                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div className={response.status ? 'p-1' : 'p-1 d-none'}>
                                                                        <div className="row">
                                                                            <ul class="list-group">
                                                                                <li class="list-group-item"><b>Total User : </b>{response.total_users}</li>
                                                                                <li class="list-group-item"><b>Successfully Uploaded : </b> {response.uploaded_users}</li>
                                                                                <li class="list-group-item"><b>Duplication Email : </b>{response.duplicate_users}</li>
                                                                                <li class="list-group-item"><b>Failed due to incorrect data : </b>{response.incorrect_data}</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="col-sm-6">
                                            <div className=" shadow-none card">
                                                <div className='card-block'>
                                                    <div className="account-box">
                                                        <div className="card-box shadow-none p-4">
                                                            <div className="p-4">

                                                                <div className="form-group mb-3">
                                                                    <label for="projectName" className="form-label">Login page Image</label><span> (Dim.1600X1000, Size less then 1 MB)</span>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        name="files"
                                                                        id="fileInput"
                                                                        accept="image/png, image/gif, image/jpeg"
                                                                        onChange={(e) => {
                                                                            handleChange({
                                                                                target: {
                                                                                    name: 'files',
                                                                                    value: e.target.files[0],
                                                                                    type: 'file',
                                                                                },
                                                                            });
                                                                        }} />

                                                                </div>
                                                                <div className="form-check mb-3">
                                                                    <input type="checkbox" className="form-check-input" name='show_login_page_text' id="show_login_page_text" onChange={() => {
                                                                        setLoginImage({
                                                                            ...loginImage,
                                                                            show_text: !loginImage.show_text
                                                                        })
                                                                    }} checked={loginImage.show_text} />
                                                                    <label htmlFor="show_login_page_text" className="form-check-label">Show Login Page Text</label>
                                                                </div>
                                                                <div className="form-group row">
                                                                    <div className="col-12 text-end">
                                                                        <button className="btn btn-primary w-md waves-effect waves-light" onClick={handleAddOrUpdateImage}>{loginImage.button_name}</button>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className="p-4 einsteen_image">
                                                                <label htmlFor="show_login_page_text" className="form-check-label">Current Login Page Image</label>
                                                                <img src={loginImage.BackgroundImage} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </div>
            </div>
            <Modal show={show_loader} aria-labelledby="contained-modal-title-vcenter" size="sm" centered>
                <Modal.Body style={{ textAlign: 'center' }}>
                    <Spinner animation="border" variant="info" size="lg" >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Setting
