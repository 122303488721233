import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'

import DataTable from 'react-data-table-component'
import Apis from '../../../../api'
import Moment from 'moment';
// Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
function convertArrayOfObjectsToCSV(array) {

    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(array[0]);


    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;


    array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];

            ctr++;

        });
        result += lineDelimiter;

    });

    return result;

}


function downloadCSV(array) {

    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);

    if (csv == null) return;

    const filename = 'Root-Cause-Report-' + new Date().toDateString() + '.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;

    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();

}

const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Export</Button>;

const columns = [
    {
        name: 'Task Name',
        selector: row => row.task,
        sortable: true
    },
    {
        name: 'Reason',
        selector: row => row.reason,
        sortable: true,
    },
    {
        name: 'Reason description',
        selector: row => row.reason_description,
        sortable: true,
    },
    {
        name: 'Team',
        selector: row => row.team,
        sortable: true,
    },
    {
        name: 'Project-Plan-Workpackage',
        selector: row => row.proj_plan_workpackage,
        sortable: true,
    },
    {
        name: 'Plan date',
        selector: row => row.date,
        sortable: true,
    },
];

const RootCausesReportTable = ({ teamId, toDate, fromDate, teams, subproject, phase_plane, work_packages, location_id }) => {

    const [reason_task, setReasonTask] = useState([])
    const [pending, setPending] = React.useState(true);

    useEffect(() => {
        const filter = [{ is_active: false }, { not_completed_tasks_count: 1 }]
        let filters = [{ type: 'Task' }]
        let demo
        if (teamId && teamId !== '$$all') {
            demo = { team_id: teamId }
            filter.push(demo)

        }
        if (fromDate) {
            demo = { closing_date: { $gte: new Date(fromDate).getTime() } }
            filter.push(demo)
        }
        if (toDate) {
            demo = { closing_date: { $lte: new Date(toDate).getTime() } }
            filter.push(demo)
        }


        if (subproject && subproject.value !== '$$all') {
            demo = { "subproject.value": subproject.value }
            filters.push(demo)
        }
        if (phase_plane && phase_plane.value !== '$$all') {
            demo = { "phase_plane.value": phase_plane.value }
            filters.push(demo)
        }
        if (work_packages && work_packages.value !== '$$all') {
            demo = { "work_packages.value": work_packages.value }
            filters.push(demo)
        }
        if (location_id) {
            demo = { location_id: location_id }
            filters.push(demo)
        }

        const raw = {
            "filter": filter,
            "filters": filters
        }
        Apis.getAnalytice(raw).then((response) => {
            setTimeout(() => {
                DataTableDate(response.data)
            }, 1000);

        }).catch((error) => {
            console.log(error.response.data)
        })

        const DataTableDate = (taskdata) => {
            const commit_data = taskdata.commit.reverse()
            const tasks = taskdata.tasks
            const reasonCodes = taskdata.reason_codes
            let list = []

            commit_data.map((commit, key) => {
                let task_index = FindArryIndex(tasks, commit.task_id)
                let reson_index = FindArryIndex(reasonCodes, commit.reason_code_id)
                if (reson_index === -1) {
                    reson_index = 0
                }
                if (task_index !== -1) {
                    let demo = {
                        id: key + 1,
                        task: tasks[task_index].name,
                        reason: reasonCodes[reson_index].reason,
                        reason_description: commit.reason_description === "" ? reasonCodes[reson_index].description : commit.reason_description,
                        team: teams[FindArryIndex(teams, commit.team_id)].name,
                        proj_plan_workpackage: 'Project' + tasks[task_index].subproject.label + '-' + tasks[task_index].phase_plane.label + '-' + tasks[task_index].work_packages.label,
                        date: Moment(new Date(commit.closing_date)).format("Do MMM YYYY")
                    }

                    list.push(demo)
                }


                return list
            })

            setReasonTask(list)
            setPending(false)
        }


    }, [fromDate, teamId, toDate, teams, subproject, phase_plane, work_packages, location_id])

    const FindArryIndex = (arr, id) => {
        for (var i = 0; i < arr.length; i++) {
            var el = arr[i];
            if (el._id === id) {
                return i
            }
        }
        return -1
    }

    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(reason_task)} />, [reason_task]);

    return (
        <> <DataTable
            title="Team Member Association"
            actions={actionsMemo}
            columns={columns}
            data={reason_task}
            progressPending={pending}
            pagination

        /></>
    )
}

export default RootCausesReportTable